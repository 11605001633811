<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Convenios" cardTextFooter :exportInfo="items">
      <template v-slot:buttons>
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="20" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.fecha_creacion`]="{ item }">
          {{ dateFormat(item.fecha_creacion) }}
        </template>
        <template v-slot:[`item.monto`]="{ item }">
          {{ '$' + formatNumberToMoney(item.monto) }}
        </template>
        <template v-slot:[`item.nombre_completo`]="{ item }">
          {{ item.solicitud_prestamo.cliente.persona.nombre + ' ' + item.solicitud_prestamo.cliente.persona.apellido_paterno + ' ' + item.solicitud_prestamo.cliente.persona.apellido_materno }}
        </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn } from "@/components"
import services from "@/utils/services"
import moment from 'moment'

export default {
  name: "agreement",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
  },
  data: () => ({
    alert: {
      active: false,
      color: 'error',
      msg: '',
    },
    loading: false,
    search: '',
    headers: [
      { text: "ID de solicitud", align: "left", sortable: true, value: "solicitud_prestamo.idsolicitud_prestamo" },
      { text: "ID de cliente", align: "left", sortable: true, value: "solicitud_prestamo.cliente.idcliente" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre_completo" },
      { text: "Monto", align: "left", sortable: true, value: "monto" },
      { text: "No. semanas", align: "left", sortable: true, value: "semanas" },
      { text: "Semana de inicio", align: "left", sortable: true, value: "semana_inicio" },
      { text: "Fecha creación", align: "left", sortable: true, value: "fecha_creacion" },
    ],
    items: [],
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.loading = true
      this.axios.get(services.routes.agreement)
        .then((response) => {
          this.items = response.data.data
          for (let item of this.items) {
            item.idsolicitud_prestamo = item.solicitud_prestamo.idsolicitud_prestamo
            item.idcliente = item.solicitud_prestamo.cliente.idcliente
            item.nombre_completo = item.solicitud_prestamo.cliente.persona.nombre + ' ' + item.solicitud_prestamo.cliente.persona.apellido_paterno + ' ' + item.solicitud_prestamo.cliente.persona.apellido_materno
            item.fecha = this.dateFormat(item.fecha_creacion)
          }
        })
        .catch((error) => {
          this.alert.active = true
          this.alert.color = 'error'
          this.alert.msg = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    formatNumberToMoney(num) {
      try {
        const aux = Number(num);
        return aux
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } catch (e) {
        return ''
      }
    }
  },
};
</script>

<style scoped>
</style>
